import React from 'react';
import Hero from '../components/hero';

export default () => {
  return (
    <>
      <Hero />
    <h1>I love the LIFE</h1>
    </>
  );
};
